import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/bill-of-lading'

class CFRReleasesService extends ApiService {
    constructor(){
        super()
    } 
    getUrl(){
        return URL
    }
    getCFRInfo(query){
        return http.get(`${URL}/cfr?search=` + query)
    }
    getReleasedBLs(query){
        return http.get(`${URL}?is_released=1&search=` + query)
    }
    releaseCFR(id, is_released){
        return http.put(`${URL}/cfr/` + id, {'is_released': is_released})
    }
}

export default new CFRReleasesService()