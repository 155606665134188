<template>
    <div class="w-100">
        <div class="shipload-header">
            <h1 class="pb-3"> 
                <span :class="{'text-decoration-underline hoverable': chosen}" @click="back(true)">{{ $tc('navigation.cfr_releases', 2) }}</span>
            </h1>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="manual-tab" data-bs-toggle="tab" data-bs-target="#manual" type="button" role="tab" aria-controls="manual" aria-selected="true">{{$t('manifest.release_1')}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="released-tab" data-bs-toggle="tab" data-bs-target="#released" type="button" role="tab" aria-controls="released" @click="setReleasedTab">{{$t('manifest.release_2')}}</button>
                </li>
            </ul>
        </div>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="manual" role="tabpanel" aria-labelledby="manual-tab" style="min-width: 1510px">
                <div class="d-flex">
                    <div class="form-group search w-100">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <input class="form-control" v-model="cfrQuery" @keypress.enter="loadBLs" @change="loadBLs" :placeholder="$t('overview.search')" autocomplete="off">
                    </div>
                </div>
                <div :class="'fw-bold ' + (!loading && resultsCount != 0 ? 'd-flex' : 'd-none')">
                    <div class="col-2 col-form-label-sm sortable" :class="{'selected-header': sorter.column == 'invoiceno'}" @click="sort(cfrOptions, 'invoiceno', sorter)"> 
                        {{ $t('accounting.invoice_number') }} <span :data-sort="sorter.direction"></span>
                    </div>
                    <div class="col-1 col-form-label-sm sortable" :class="{'selected-header': sorter.column == 'invoicetotal'}" @click="sort(cfrOptions, 'invoicetotal', sorter)"> 
                        {{ $tc('overview.amount', 2) }} <span :data-sort="sorter.direction"></span>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-auto col-form-label-sm" style="width: 100px"> 
                                {{ $t('operations.release') }}?
                            </div>                                
                            <div class="col-auto col-form-label-sm" style="width: calc(100% - 980px)"> 
                                B/L <span :data-sort="sorter.direction"></span>
                            </div>
                            <div class="col-auto col-form-label-sm" style="width: 150px"> 
                                {{ $tc('navigation.ports') }} <span :data-sort="sorter.direction"></span>
                            </div>
                            <div class="col-auto col-form-label-sm" style="width: 300px"> 
                                {{ $tc('overview.representative') }} <span :data-sort="sorter.direction"></span>
                            </div>
                            <div class="col-auto col-form-label-sm" style="width: 250px"> 
                                {{ $tc('form.email') }} <span :data-sort="sorter.direction"></span>
                            </div>
                            <div class="col-auto col-form-label-sm" style="width: 100px">
                                {{ $t('overview.type') }} <span :data-sort="sorter.direction"></span>
                            </div>
                            <div class="col-auto col-form-label-sm" style="width: 80px">
                                {{ $t('masterdata.export') }} <span :data-sort="sorter.direction"></span>
                            </div>
                        </div>
                    </div>
                </div>

                    
                    <!--<div class="col-auto col-form-label-sm" style="width: 60px"> 
                        Credit
                    </div>-->
                    <!--<div class="col-auto col-form-label-sm sortable" style="width: calc(55% - 340px)" :class="{'selected-header': sorter.column == 'number'}" @click="sort(1, 'number', sorter)"> 
                        B/L <span :data-sort="sorter.direction"></span>
                    </div>
                    <div class="col-auto col-form-label-sm sortable" style="width: 120px" :class="{'selected-header': sorter.column == 'bl_type'}" @click="sort(1, 'bl_type', sorter)">
                        {{ $t('overview.type') }} <span :data-sort="sorter.direction"></span>
                    </div>
                    <div class="col-auto col-form-label-sm sortable" style="width: 80px" :class="{'selected-header': sorter.column == 'release_type'}" @click="sort(1, 'release_type', sorter)">
                        {{ $t('masterdata.export') }} <span :data-sort="sorter.direction"></span>
                    </div>-->
                    
                
                <div v-if="loading"> <i class="fa fa-spin fa-spinner"></i>&nbsp;{{ $t('form.please_wait') }}</div>
                <div class="job-block" v-else>
                    <div v-for="(billoflading) in cfrOptions" :key="billoflading.id" class="d-flex py-1" :class="{'border-top': billoflading.invoiceno}" :for="'unit-' + billoflading.id" v-show="!billoflading.hidden">
                        <div class="col-2"> {{ billoflading.invoice.invoiceno }} </div>
                        <div class="col-1"> {{ billoflading.invoice.invoicetotal }} </div>
                        <div class="col-9">
                            <div v-for="(bl) in billoflading.bls" :key="bl.id" class="row hoverable-light">
                                <div class="col-auto" style="width: 100px; padding-top: 1px; padding-bottom: 1px">
                                    <FormItem :id="'release-' + bl.id" v-model="bl.is_released" type="slider" :options="[1,0]" @onchange="autoSaveCFR('release', bl)" 
                                              :disabled="bl.release_type != 'EMAIL' || bl.is_released || !bl.can_be_released" extraOption="thin" />
                                </div>
                                <!--<div class="col-auto" style="width: 60px">
                                    <input class="form-check-input optional" type="checkbox" :id="'credit-' + bl.id" v-model="bl.is_active" @change="autoSaveCFR('credit', bl)" :disabled="bl.hidden" />
                                </div>-->
                                <div class="col-auto" :class="{'text-lightgray': bl.release_type != 'EMAIL'}" style="width: calc(100% - 980px)"> {{ bl.number }} </div>
                                <div class="col-auto" :class="{'text-lightgray': bl.release_type != 'EMAIL'}" style="width: 150px"> {{ billoflading.port_of_final_destination ? billoflading.port_of_final_destination.name : billoflading.port_of_discharge.name  }} </div>
                                <div class="col-auto" :class="{'text-lightgray': bl.release_type != 'EMAIL', 'text-danger': !bl.can_be_released}" style="width: 300px"> {{ billoflading.representative && billoflading.representative.relation ? billoflading.representative.relation.name : 'unknown/deleted' }} </div>
                                <div class="col-auto" :class="{'text-lightgray': bl.release_type != 'EMAIL', 'text-danger': !bl.can_be_released}" style="width: 250px" v-html="billoflading.cfr_emails.split('\n').join('<br>')"></div>
                                <div class="col-auto" :class="{'text-lightgray': bl.release_type != 'EMAIL', 'text-danger': bl.bill_of_lading_status_id != 3}" style="width: 100px"> {{ bl.bl_type }} </div>
                                <div class="col-auto" :class="{'text-lightgray': bl.release_type != 'EMAIL', 'text-danger': bl.bill_of_lading_status_id != 3}" style="width: 80px"> {{ bl.release_type }} </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="cfrNotFound && !loading"> {{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }} </div>
                </div>
            </div>

            <div class="tab-pane fade show" id="released" role="tabpanel" aria-labelledby="released-tab">
                <div class="d-flex">
                    <div class="form-group search w-100">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <input class="form-control" v-model="releasedQuery" @keypress.enter="loadReleases" @change="loadReleases" :placeholder="$t('overview.search')" autocomplete="off">
                    </div>
                </div>
                <div class="row fw-bold" v-show="releaseOptions.length && !loading">
                    <div class="col-2 col-form-label-sm sortable" :class="{'selected-header': sorterRel.column == 'number'}" @click="sort(releaseOptions, 'number', sorterRel)"> 
                        B/L <span :data-sort="sorterRel.direction"></span>
                    </div>
                    <div class="col-2 col-form-label-sm sortable" :class="{'selected-header': sorterRel.column == 'voyage'}" @click="sort(releaseOptions, 'voyage', sorterRel)"> 
                        {{ $tc('navigation.voyages', 1) }} <span :data-sort="sorterRel.direction"></span>
                    </div>
                    <div class="col-auto col-form-label-sm sortable" style="width: 120px" :class="{'selected-header': sorterRel.column == 'amount_collect'}" @click="sort(releaseOptions, 'amount_collect', sorterRel)"> 
                        {{ $t('operations.collect') }} <span :data-sort="sorterRel.direction"></span>
                    </div>
                    <div class="col-1 col-form-label-sm sortable" :class="{'selected-header': sorterRel.column == 'port_of_discharge_name'}" @click="sort(releaseOptions, 'port_of_discharge_name', sorterRel)"> 
                        PoD <span :data-sort="sorterRel.direction"></span>
                    </div>
                    <div class="col-auto col-form-label-sm sortable" style="width: calc(50% - 340px)" :class="{'selected-header': sorterRel.column == 'email'}" @click="sort(releaseOptions, 'email', sorterRel)"> 
                        {{ $t('form.email') }} <span :data-sort="sorterRel.direction"></span>
                    </div>
                    <div class="col-auto col-form-label-sm sortable" style="width: 120px" :class="{'selected-header': sorterRel.column == 'user'}" @click="sort(releaseOptions, 'user', sorterRel)">
                        {{ $t('overview.source') }} <span :data-sort="sorterRel.direction"></span>
                    </div>
                    <div class="col-auto col-form-label-sm sortable text-center" style="width: 80px" :class="{'selected-header': sorterRel.column == 'result'}" @click="sort(releaseOptions, 'result', sorterRel)">
                        {{ $t('overview.result') }} <span :data-sort="sorterRel.direction"></span>
                    </div>
                    <div class="col-auto col-form-label-sm sortable" style="width: 120px" :class="{'selected-header': sorterRel.column == 'release_date_utc'}" @click="sort(releaseOptions, 'release_date_utc', sorterRel)">
                        {{ $t('overview.date') }} <span :data-sort="sorterRel.direction"></span>
                    </div>
                </div>
                <div class="job-block">
                    <div v-for="(bl) in releaseOptions" :key="bl.id" class="row border-top py-1" :for="'unit-' + bl.id" v-show="!bl.hidden">
                        <div class="col-2"> {{ bl.number }} </div>
                        <div class="col-2"> {{ bl.voyage }} </div>
                        <div class="col-auto" style="width: 120px"> 
                            <span v-show="bl.payment_option == 'COLLECT'"> {{'USD ' + formatNumber(bl.amount_collect)}}</span> 
                            <i v-show="bl.payment_option == 'PREPAID'" class="fa fa-close text-danger"></i> 
                        </div>
                        <div class="col-1"> {{ bl.port_of_discharge_name }} </div>
                        <div class="col-auto" style="width: calc(50% - 365px)">
                            <span v-if="!bl.expanded">{{ bl.email }}</span>
                            <div v-else v-for="email in bl.emails" :key="email">{{email}}</div>
                        </div>
                        <div class="col-auto ps-2 pe-0" style="width: 25px">
                            <button type="button" class="btn btn-sm p-0" v-show="bl.multi_email" @click="expand(bl)"> 
                                <i class="fa" :class="{'fa-chevron-up': bl.expanded, 'fa-chevron-down': !bl.expanded}"></i> 
                            </button>
                        </div>
                        <div class="col-auto" style="width: 120px"> {{ bl.user }} </div>
                        <div class="col-auto text-center" style="width: 80px"> 
                            <i class="fa" :class="{'fa-clock text-muted': bl.result == 'pending', 'fa-check text-success': bl.result == 'success', 'fa-close text-danger': bl.result == 'failed'}"></i> 
                        </div>
                        <div class="col-auto" style="width: 120px"> {{ bl.release_date }} </div>
                    </div>
                    <div v-show="blsNotFound && !loading"> {{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }} </div>
                </div>
                <div v-show="loading"> <i class="fa fa-spin fa-spinner"></i>&nbsp;{{ $t('form.please_wait') }}</div>
            </div>
        </div>
    </div>

</template>

<script>
    import cfrReleasesService from '@/services/CFRReleasesService';

    export default {
        data() {
            return {
                options: {},
                optionsArray: [],
                cfrOptions: [],
                rawCfrOptions: [],
                releaseOptions: [],
                invalidJobs: [],
                resultsCount: 0,
                item: null,
                itemLoading: false,
                itemFormRef: {},
                loading: false,
                chosen: false,
                search: '',
                cfrQuery: '',
                releasedQuery: '',
                cfrNotFound: false,
                blsNotFound: false,
                sorter: {column: '', direction: 1},
                sorterRel: {column: '', direction: 1},
                tab: 'customer',
                shiploadOption: null,
                shiploadOptionOfficeId: null
            }
        },
        methods: {
            sort(list, column, sorter){
                if(sorter.column == column)
                    sorter.direction *= -1;
                else
                    sorter.direction = 1;
                sorter.column = column;
                list.sort((a, b) => {
                    return a[column] > b[column] ? sorter.direction : -sorter.direction;
                });
            },
            capitalize(value){
                return value[0] + value.substring(1).toLowerCase();
            },
            setReleasedTab(){
                if(!this.releaseOptions.length){
                    this.loadReleases();
                }
            },
            loadBLs(){
                if(this.loading) return;
                this.loading = true;
                cfrReleasesService.getCFRInfo(this.cfrQuery).then(response => {
                    let values = Object.values(response.data);
                    this.cfrOptions = this.ToGroupedArray(values);
                    this.resultsCount = values.length;
                    this.cfrNotFound = this.resultsCount == 0;
                })
                .catch(error =>  console.log('error', error) )
                .finally(() => this.loading = false);
            },
            loadReleases(){
                this.loading = true;
                cfrReleasesService.getReleasedBLs(this.releasedQuery).then(response => {
                    this.releaseOptions = [];
                    this.blsNotFound = response.data.length == 0;
                    response.data.forEach(d => {
                        function setName(name){
                            const explname = name.split(' ');
                            return explname[0] + ' ' + (explname[1] ? explname[1][0] : '?') + '.';
                        }
                        const emails = d.cfr ? d.cfr.email.split(',') : [''];
                        this.releaseOptions.push({
                            'number': d.number,
                            'payment_option': d.payment_option,
                            'amount_collect': d.amount_collect,
                            'bl_type': d.bl_type,
                            'release_type': d.release_type,
                            'port_of_discharge_name': d.port_of_discharge_name,
                            'voyage': d.voyage ? d.voyage.number : '-',
                            'multi_email': emails.length > 1,
                            'email': emails[0],
                            'emails': emails,
                            'expanded': 0,
                            'result': d.cfr && d.cfr.result ? d.cfr.result : 'pending',
                            'release_date_utc': d.cfr ? d.cfr.release_date : '-',
                            'release_date': d.cfr ? this.formatDate(d.cfr.release_date, "dateTimeAmerican") : '-',
                            'user': d.cfr && d.cfr.source_user ? setName(d.cfr.source_user.name) : 'auto'
                        })
                    })
                })
                .catch(error =>  console.log('error', error) )
                .finally(() => this.loading = false);
            },
            autoSaveCFR(mode, bl){
                console.log(mode);
                if(confirm(`Are you sure you want to release ${bl.number}?`)){
                    cfrReleasesService.releaseCFR(bl.id, bl.is_released)
                    .catch(error =>  {
                        console.log('error', error);
                        bl.is_released = 0;
                        this.toastError(error);
                    });
                } else{
                    bl.is_released = 0;
                }
            },
            ToGroupedArray(arr){
                let options = {};
                for(let obj of arr){
                    if(options[obj.invoice.invoiceno]){
                        options[obj.invoice.invoiceno].bls.push(obj);
                    }
                    else{
                        let currPrefix = (obj.invoice.invoicecurrency ? obj.invoice.invoicecurrency.substring(0, 3) : 'EUR');
                        if(currPrefix == "USD") currPrefix = "$";
                        else if(currPrefix == "EUR") currPrefix = "€";
                        else if(currPrefix == "GBP") currPrefix = "£";
                        else currPrefix = (currPrefix + ' ');
                        options[obj.invoice.invoiceno] = obj;
                        options[obj.invoice.invoiceno].amount = currPrefix + this.formatCurrency(obj.invoice.invoicetotal);
                        options[obj.invoice.invoiceno].bls = [obj];
                    }
                }
                return Object.values(options);
            },
            expand(bl){
                bl.expanded = !bl.expanded;
            }
        },
        mounted() {
            this.itemFormRef = this.$refs.ItemForm;
        }
    }
</script>
<style scoped>
.sortable:hover, .hoverable:hover{
    background-color: #eee;
    cursor: pointer
}
.hoverable-light:hover{
    background-color: #f8f8f8
}
.selected-header{
  background-color: #cef !important
}
.selected-header > [data-sort="1"]::before{
  content: "\2193"
}
.selected-header > [data-sort="-1"]::before{
  content: "\2191"
}
.shipload-header{
    position: fixed;
    top: 0;
    left: 18rem;
    width: calc(100% - 18rem);
    padding: 15px;
    background-color: white;
    z-index: 120
}
#myTabContent{
    position: relative;
    top: 8rem;
}
.col-1p5{
    flex: 0 0 auto;
    width: 12%;
}
.text-lightgray{
    color: #aaa
}
</style>